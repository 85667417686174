<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{training: $route.params.id, api_key: currentUser.api_key}">

        <div class="row col-12">
            <validated-date-picker :disabled-date="disabledBeforeToday" class="col-4" label="Start Date"
                                   v-model="model.start_date" :rules="rules.start_date" :disabled="loading"
                                   format="DD-MM-YYYY" value-type="YYYY-MM-DD" @input="setDateLimit(model.start_date)"/>

            <validated-date-picker :disabled-date="disabledEndDate" class="col-4" label="End Date"
                                   v-model="model.end_date" format="DD-MM-YYYY" value-type="YYYY-MM-DD"
                                   :rules="rules.end_date" :disabled="loading"/>

            <validated-input class="col-4" label="Location" v-model="model.location"
                             :rules="rules.location" :disabled="loading"/>
        </div>

        <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
             loading-text="Saving..." :disabled="loading"/>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddSchedule',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl     : urls.admin.training.schedule.addEdit,
            start_date : null,
            rules      : {
                start_date : {
                    required : true
                },
                end_date : {
                    required : true
                },
                location : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Schedule..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },

        disabledBeforeToday (date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },

        setDateLimit (date) {
            this.start_date = date;
        },

        disabledEndDate (date) {
            let today;
            if (this.start_date) {
                today = new Date(this.start_date);
            } else {
                today = new Date();
                today.setHours(0, 0, 0, 0);
            }
            return date < today;
        },

        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
