<template>
    <div class="card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'SCHEDULE'"></h4>
            <div>
                <btn color="success" icon="fa fa-plus" size="md" @click="$refs.scheduleAddModal.show()" text="Add"/>
            </div>
        </div>
        <vue-table :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </vue-table>

        <modal title="Add Schedule" ref="scheduleAddModal" width="60r" :no-close-on-backdrop="true" header-color="primary">
            <AddSchedule @success="formSuccess"></AddSchedule>
        </modal>

        <modal title="Edit Schedule" ref="scheduleEditModal" :no-close-on-backdrop="true" width="60r" header-color="primary">
            <EditSchedule :initial-data="editingItem" @success="formSuccess"></EditSchedule>
        </modal>

        <delete-modal ref="scheduleDeleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Schedule <b v-html="deletingItem && deletingItem.location"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.id"></b>.
            </template>
        </delete-modal>

    </div>
</template>

<script>
import urls from '../../../../data/urls';
import AddSchedule from './AddSchedule';
import EditSchedule from './EditSchedule';

export default {
    name       : 'Schedule',
    components : { AddSchedule, EditSchedule },
    data () {
        return {
            listUrl      : urls.admin.training.schedule.list + '?training=' + this.$route.params.id,
            deleteUrl    : urls.admin.training.schedule.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name       : 'start_date',
                    sortField  : 'start_date',
                    title      : 'Start Date',
                    titleClass : ''
                },
                {
                    name       : 'end_date',
                    sortField  : 'end_date',
                    title      : 'End Date',
                    titleClass : ''
                },
                {
                    name       : 'location',
                    sortField  : 'location',
                    title      : 'Location',
                    titleClass : ''
                },
                {
                    name       : '__slot:actions',
                    title      : 'Actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.scheduleAddModal.close();
            refs.scheduleEditModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.scheduleEditModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.scheduleDeleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$notify('Successfully Deleted Schedule..!', 'Success',
                {
                    type : 'warning'
                }
            );
            this.$refs.scheduleDeleteModal.close();
            this.$refs.table.refreshTable();
        },

        schedule (item) {
            this.$router.push({ path : '' });
        }
    }
};
</script>

<style scoped>

</style>
